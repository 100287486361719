import { Injectable } from '@angular/core';
import { GlobalProviderService } from '../providers/global-provider.service';
import { environment } from '../../environments/environment';
import Bugsnag from '@bugsnag/js';
import { loadStripeTerminal, FetchConnectionTokenFn, TerminalProps } from '@stripe/terminal-js';
import { HelperService } from './helper.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { StringifyOptions } from 'querystring';

@Injectable({
  providedIn: 'root',
})

export class CmoapiService {
  getLinkedMenuDetails() {
    throw new Error('Method not implemented.');
  }
  public cmoUrl = environment.CMO_API_URL;
  public cloudFunctionUrl = environment.CMO_CLOUD_API_URL;
  public cmoTicketUrl = environment.CMO_TICKET_API;
  public cmoRiderUrl = environment.CMO_RIDER_API;

  constructor(
    private globalProvider: GlobalProviderService,
    public helper: HelperService,
    private firestore: AngularFirestore,
  ) { }

  public autoLogin(token: string) {
    const url = this.cmoUrl + `autologin/${token}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public registerUser(userObj) {
    const url = this.cmoUrl + 'register';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, userObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public verifyOTP(otpObj) {
    const url = this.cmoUrl + 'verify_otp';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, otpObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public verifyCaptcha(token: string) {
    const url = this.cmoUrl + 'verify_captcha';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, { token })
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public resendOTP(otpObj) {
    const url = this.cmoUrl + 'resend_otp';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, otpObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getUserProfile() {
    const url = this.cmoUrl + 'user/profile';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public updateUserProfileNumber(obj: object) {
    const url = `${this.cmoUrl}update_profile_mobile`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getStoreOrders(storeId, perPageData, pageNumber) {
    const url = this.cmoUrl + 'store/orders_by_store/' + storeId + '?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getSingleOrderDetails(id) {
    const url = this.cmoUrl + 'store/order/' + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public filterOrderDetails(filterObj, perPageData, pageNumber) {
    const url = this.cmoUrl + 'store/filter_orders?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, filterObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getStoreCustomers(perPageData, pageNumber) {
    const url = this.cmoUrl + 'store/customers?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getSingleCustomerDetails(customerId) {
    const url = this.cmoUrl + 'store/customer/' + customerId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getCustomerOrders(customerId) {
    const url = this.cmoUrl + 'store/customer/' + customerId + '/orders';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public async fetchStripConnectionToken(storeId) {
    const url = this.cmoUrl + 'store/stores/' + storeId + '/connection_token';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public addStore(storeObj) {
    const url = this.cmoUrl + 'store/stores';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, storeObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public createPaymentIntent(storeObj, storeId) {
    const url = this.cmoUrl + '/store/stores/' + storeId + '/create_payment_intent';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, storeObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public capturePaymentIntent(storeObj, storeId) {
    const url = this.cmoUrl + '/store/stores/' + storeId + '/capture_payment';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, storeObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public updateStore(storeObj, storeId) {
    const url = this.cmoUrl + 'store/stores/update/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, storeObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getAllStores(stripeFlag?: boolean, actionFlag?: boolean) {
    const url = this.cmoUrl + `store/stores?per_page=0&page=1&stripe=${stripeFlag}&onboard_actions=${actionFlag}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getStoreList(perPageData, pageNumber, stripeFlag?: boolean, actionFlag?: boolean) {
    const url = this.cmoUrl + `store/stores?per_page=${perPageData}&page=${pageNumber}&stripe=${stripeFlag}&onboard_actions=${actionFlag}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getStoreTransactionsFullList(stripe_account_id: string) {
    const url = this.cmoUrl + `store/stores/payouts/${stripe_account_id}/list`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getStripeLinkedEmailList() {
    const url = this.cmoUrl + `store/stores/payouts/accounts_list`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getPayoutTransferOrderList(stripe_account_id: string, payoutId: string) {
    const url = this.cmoUrl + `store/stores/payouts/${stripe_account_id}/orders/${payoutId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getStoreTransactionsList(store_id: string) {
    const url = this.cmoUrl + 'store/stores/transactions/' + store_id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getSingleStoreDetails(id) {
    const url = this.cmoUrl + 'store/stores/' + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }
  public updateStoreLogo(obj, storeId) {
    const url = this.cmoUrl + 'store/stores/update_logo/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public removeStoreLogo(storeId) {
    const url = this.cmoUrl + 'store/stores/remove_logo/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, null)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public updateStoreCardImage(obj: object, storeId: string) {
    const url = this.cmoUrl + 'store/stores/update_card_image/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public removeStoreCardImage(storeId: string) {
    const url = this.cmoUrl + 'store/stores/remove_card_image/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public uploadMenu(obj, storeId) {
    const url = this.cmoUrl + 'store/stores/upload_menu/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public removeMenuImage(obj, storeId) {
    const url = this.cmoUrl + 'store/stores/delete_menu/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * store image gallery
   * @param obj
   * @param storeId
   */
  public uploadStoreGallery(obj, storeId) {
    const url = this.cmoUrl + 'store/stores/upload_gallery/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }
  /**
   * Remove store gallery images
   * @param obj
   * @param storeId
   */
  public removeStoreGalleryImage(obj, storeId) {
    const url = this.cmoUrl + 'store/stores/delete_gallery_image/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public updateBusinessHours(obj, storeId) {
    const url = this.cmoUrl + 'store/v2/stores/update_business_hours/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getStoreOnBordingDetails(storeId: string) {
    const url = this.cmoUrl + `store/${storeId}/actions`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getAllStoreManagers() {
    const url = this.cmoUrl + 'store/managers';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getAllStoreWiseManagers(storeId: string) {
    const url = this.cmoUrl + `store/managers/${storeId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getStoreManagersList(perPageData, pageNumber) {
    const url = this.cmoUrl + 'store/managers?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getSingleManagerDetails(managerId) {
    const url = this.cmoUrl + 'store/managers/' + managerId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public addStoremanager(manaerObj) {
    const url = this.cmoUrl + 'store/managers';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, manaerObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public updateManagerDetails(managerObj, id) {
    const url = this.cmoUrl + 'store/managers/' + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, managerObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public deleteManagerDetails(id) {
    const url = this.cmoUrl + 'store/managers/delete/' + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public assigenStoreManager(assigenObj, storeId) {
    const url = this.cmoUrl + 'store/stores/assign_manager/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, assigenObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  //#region staffs

  public getAllStaffs() {
    const url = this.cmoUrl + 'store/staffs';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getStaffsList(perPageData, pageNumber) {
    const url = this.cmoUrl + 'store/staffs?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getStaffsFilter(obj: object, perPageData: number, pageNumber: number) {
    const url = this.cmoUrl + `store/staffs/filter?per_page=${perPageData}&page=${pageNumber}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getSingleStaffDetails(staffId: string) {
    const url = this.cmoUrl + `store/staffs/${staffId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public addStaff(obj: object) {
    const url = this.cmoUrl + 'store/staffs/add';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public updateStaffDetails(obj: object, id: string) {
    const url = this.cmoUrl + `store/staffs/update/${id}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public deleteStaffDetails(id: string) {
    const url = this.cmoUrl + `store/staffs/delete/${id}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public assignStaffDetails(obj: object, id: string) {
    const url = this.cmoUrl + `store/staffs/assign_role/${id}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getAllStoreWiseStaffs(storeId: string) {
    const url = this.cmoUrl + `store/assign_staffs/${storeId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  //#endregion

  public getCommonHoliday() {
    const url = this.cmoUrl + 'admin/master/list/common_holidays';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public createSpecialHours(obj: object, storeId: string) {
    const url = this.cmoUrl + `store/v2/stores/${storeId}/add_special_hours`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public updateSpecialHours(obj: object, storeId: string, hourId: string) {
    const url = this.cmoUrl + `store/v2/stores/${storeId}/update_special_hours/${hourId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public deleteSpecialHours(storeId: string, hourId: string) {
    const url = this.cmoUrl + `store/stores/${storeId}/delete_special_hours/${hourId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public createOrder(orderObj) {
    const url = this.cmoUrl + 'store/place_order';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, orderObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /***
   * Get All Discount
   */

  public getStoreDiscountList(id, perPageData, pageNumber) {
    const url = this.cmoUrl + 'store/' + id + '/discounts?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getSingleDiscountDetails(storeId, discountId) {
    const url = this.cmoUrl + 'store/' + storeId + '/discounts/' + discountId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public addStoresDiscount(discountObj, storeId) {
    const url = this.cmoUrl + 'store/' + storeId + '/discounts';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, discountObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public updateStoresDiscount(discountObj, storeId, discountId) {
    const url = this.cmoUrl + 'store/' + storeId + '/discounts/update/' + discountId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, discountObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public deleteStoresDiscount(storeId, discountId) {
    const url = this.cmoUrl + 'store/' + storeId + '/discounts/delete/' + discountId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, null)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public updateSingleDiscountStatus(obj: object, storeId: string, discountId: string) {
    const url = this.cmoUrl + `store/${storeId}/discounts/update_status/${discountId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public applyDiscountOffer(discountId: string) {
    const url = this.cmoUrl + 'cart/apply_discount/' + discountId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, null)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public filterDiscount(obj: object) {
    const url = this.cmoUrl + `store/discounts/filter`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /***
   * Get All Common Stock
   */

  public getCommonStock() {
    const url = this.cmoUrl + 'stock_items/all';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Temp Order APIs
   */
  public saveTempOrder(tempOrderObj) {
    const url = this.cmoUrl + 'store/temporders/save';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, tempOrderObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
        });
    });
  }

  /***
   * Get User All Temp Order
   */
  public getUserAllTempOrder(tempOrderObj) {
    const url = this.cmoUrl + 'store/temporders/all';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, tempOrderObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
        });
    });
  }

  /**
   * Clear All Temp Order
   */
  public clearAllTempOrder(storeObj) {
    const url = this.cmoUrl + 'store/temporders/delete_by_customer/all';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, storeObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
        });
    });
  }

  /**
   * Update user profile
   */

  updateProfile(profileObj) {
    const url = this.cmoUrl + 'user/update_profile';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, profileObj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public updateProfilePicture(data: object) {
    const url = this.cmoUrl + 'user/update_profile_picture';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, data)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public deleteProfilePicture() {
    const url = this.cmoUrl + 'user/delete_profile_picture';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, {}).then(data => {
        resolve(data);
      }, err => {
        reject(err);
      });
    });
  }

  /**
   * Get Order Statuses
   */
  public getAllOrderStatuses() {
    const url = this.cmoUrl + 'store/order_statuses';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getDynamicStatusList(status: string, type: string) {
    const url = this.cloudFunctionUrl + `orderWorkflow?currentStatus=${status}&type=${type}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.cloudHttpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Update Order Status
   */
  public orderStatusUpdate(orderStatus, orderId) {
    const url = this.cmoUrl + 'store/order/update_status/' + orderId;
    const body = {
      status: orderStatus,
    };
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, body)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Get All Categories
   */

  getAllCategories() {
    const url = this.cmoUrl + 'listings/categories';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getFulfillmentTypes() {
    const url = this.cmoUrl + 'listings/fulfillment_types';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }
  public getAllTags() {
    const url = this.cmoUrl + 'listings/tags';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public sendBusinessVerification(storeId, obj: object) {
    const url = this.cmoUrl + 'store/stores/send_verification/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public verifyBusiness(storeId, requestObject) {
    const url = this.cmoUrl + 'store/stores/verify/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, requestObject)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getAllMenuItems() {
    const url = this.cmoUrl + 'store/menu_items/';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getStoreAllMenuItems(storeId) {
    const url = this.cmoUrl + 'store/menu_items/v2/' + storeId + '/menu';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getPosOrderTotal(storeId,body) {
    const url = this.cmoUrl + 'store/stores/' + storeId + '/pos_order_totals';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url,body)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }
  

  public getAllMenuCategories(storeId, perPageData, pageNumber) {
    const url = this.cmoUrl + 'store/store_menus/' + storeId + '/all?per_page=' + perPageData + '&page='
      + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }
  /**
   * Get Category Item By  Category
   */
  public getMainCategoryMenuItem(storeId, categoryObj) {
    const url = this.cmoUrl + 'store/menu_items/' + storeId + '/by_category?per_page=0';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, categoryObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Get Category Item By Sub Category
   */
  public getCategoryMenuItem(storeId, categoryObj) {
    const url = this.cmoUrl + 'store/menu_items/' + storeId + '/by_sub_category?per_page=0';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, categoryObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Get Single Menu Item
   */

  public getSingleMenuItem(storeId: string, itemId: string) {
    const url = this.cmoUrl + 'store/menu_items/' + storeId + '/single/' + itemId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getSingleMenuItemLinkedMenuDetail(storeId: string, itemId: string, obj: object) {
    const url = this.cmoUrl + 'store/menu_items/' + storeId + '/single_using_linked_menu/' + itemId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getAllMenus(storeId, perPageData, pageNumber) {
    const url = this.cmoUrl + `store/store_menus/${storeId}/all?per_page=${perPageData}&page=${pageNumber}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  //#region Items API

  public filterItems(obj: object) {
    const url = this.cmoUrl + `store/menu_items/filter_menus`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public updateMenuItem(storeId: string, item: any) {
    const url = this.cmoUrl + `store/menu_items/${storeId}/update/${item._id}`;
    delete item['_id'];
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, item)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public addItemToMenu(storeId: string, itemId: string, obj: object) {
    const url = this.cmoUrl + `store/menu_items/${storeId}/add_to_menu/${itemId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public updateAddedItemToMenu(storeId: string, itemId: string, linkedMenuId: string, obj: object) {
    const url = this.cmoUrl + `store/menu_items/${storeId}/add_to_menu/${itemId}/update/${linkedMenuId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public deleteAddedItemToMenu(storeId: string, itemId: string, linkedMenuId: string) {
    const url = this.cmoUrl + `store/menu_items/${storeId}/add_to_menu/${itemId}/delete/${linkedMenuId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public addMenuItem(storeId: string, menuObj: object) {
    const url = this.cmoUrl + 'store/menu_items/' + storeId + '/add';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, menuObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public deleteMenuItem(storeId: string, itemId: string) {
    const url = this.cmoUrl + 'store/menu_items/' + storeId + '/remove/' + itemId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public uploadMenuItemImage(storeId, itemId, itemObj) {
    const url = this.cmoUrl + 'store/menu_items/' + storeId + '/update_image/' + itemId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, itemObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getSearchMenuItems(storeId, searchText) {
    const url = this.cmoUrl + 'listings/v2/listing/' + storeId + '/menu_items?search=' + searchText;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getSKUAutoGenerateNumber() {
    const url = this.cmoUrl + `store/menu_items/generate_sku`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

   public checkLastItemInStore(storeId: string, itemId: string) {
    const url = this.cmoUrl + `store/menu_items/v2/${storeId}/delete_check/${itemId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public sortItem(storeId: string, obj: object) {
    const url = this.cmoUrl + `store/menu_items/${storeId}/sort`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  //#endregion

  //#region menu API

  public addNewMenu(storeId: string, obj: object) {
    const url = this.cmoUrl + `store/store_menus/${storeId}/add`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getSingleMenu(storeId: string, menuId: string) {
    const url = this.cmoUrl + `store/store_menus/${storeId}/single/${menuId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public updateMenu(storeId: string, item: any) {
    const url = this.cmoUrl + `store/store_menus/${storeId}/update/${item?._id}`;
    delete item['_id'];
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, item)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public checkUniqueItems(storeId: string, menuId: string) {
    const url = this.cmoUrl + `store/store_menus/${storeId}/check_unique_items/${menuId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public deleteMenu(storeId: string, menuId: string) {
    const url = this.cmoUrl + `store/store_menus/${storeId}/remove/${menuId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public sortMenu(storeId: string, obj: object) {
    const url = this.cmoUrl + `store/store_menus/${storeId}/sort_menus`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  //#endregion

  //#region category API

  public getSingleMenuCategory(storeId: string, menuId: string, categoryId: string) {
    const url = this.cmoUrl + 'store/menu_item_categories/' + storeId + '/root/' + menuId + '/update_sub/' +
      categoryId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public updateCategory(storeId: string, menuId: string, obj: any) {
    const url = this.cmoUrl + `store/store_menus/${storeId}/menu/${menuId}/update/${obj._id}`;
    delete obj['_id'];
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public addCategory(storeId: string, menuId: string, obj: any) {
    const url = this.cmoUrl + `store/store_menus/${storeId}/menu/${menuId}/add`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public deleteCategory(storeId: string, menuId: string, categoryId: string) {
    const url = this.cmoUrl + `store/store_menus/${storeId}/menu/${menuId}/remove/${categoryId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public filterCategories(obj: object) {
    const url = this.cmoUrl + `store/store_menus/filter_category`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public sortCategory(storeId: string, menuId: string, obj: object) {
    const url = this.cmoUrl + `store/store_menus/${storeId}/menu/${menuId}/sort_categories`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  //#endregion

  /**
   * Get Store Settings
   * @param storeId
   */
  public getStoreSettings(storeId) {
    const url = this.cmoUrl + 'store/stores/settings/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Update Store Settings
   * @param storeId
   * @param settings
   */
  public updateStoreSettings(storeId, settings) {
    const url = this.cmoUrl + 'store/stores/settings/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, settings)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Get Store Account Details
   */
  public getStoreAccountDetails(storeId) {
    const url = this.cmoUrl + 'store/stores/account_details/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }
  /**
   * Get Store Account Balance Details
   */
  public getStoreAccountBalance(storeId) {
    const url = this.cmoUrl + 'store/stores/account_balance/' + storeId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getStorePayoutUpdateLink(storeId: string) {
    const url = this.cmoUrl + `store/stores/payouts/${storeId}/account_link`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Add Store Kitchen Staff
   */
  public addKitchenStaff(storeId, kStffObj) {
    const url = this.cmoUrl + 'store/' + storeId + '/kitchen_staff';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, kStffObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Get Store Kitchen Staff
   */
  public getKitchenStaff(storeId, perPageData, pageNumber) {
    const url = this.cmoUrl + 'store/' + storeId + '/kitchen_staff?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Get Kitchen Staff Details
   */
  public getKitchenStaffDetails(storeId, kStaffId) {
    const url = this.cmoUrl + 'store/' + storeId + '/kitchen_staff/' + kStaffId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Update Kitchen Staff
   */
  public updateKitchenStaff(storeId, kStaffId, kStaffObj) {
    const url = this.cmoUrl + 'store/' + storeId + '/kitchen_staff/' + kStaffId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, kStaffObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Get Kitchen Orders
   */
  public getKitchenOrders(storeId, perPageData, pageNumber) {
    const url = this.cmoUrl + 'store/kitchen_orders/' + storeId + '?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Delete Kitchen Orders
   */
  public deleteKitchenOrders(storeId, staffId) {
    const url = this.cmoUrl + 'store/' + storeId + '/kitchen_staff/delete/' + staffId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Import Menu
   */
  public importStoreMenu(storeId, menu) {
    const url = this.cmoUrl + 'store/menu_items/' + storeId + '/import';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, menu)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Forgot Password Reset Link
   */
  public forgotPassword(email) {
    const url = this.cmoUrl + 'forgot_password';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, email)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Password Reset Link
   */
  public resetPassword(token, passwordObj) {
    const url = this.cmoUrl + 'reset_password?token=' + token;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, passwordObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Upload Store Media Gallery
   */
  public uploadStoreGalleryMedia(storeId, gallery) {
    const url = this.cmoUrl + 'store/' + storeId + '/media_gallery';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, gallery)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Get Store Media Gallery
   */
  public getStoreMediaGallery(storeId, perPageData, pageNumber) {
    const url = this.cmoUrl + 'store/' + storeId + '/media_gallery?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Remove Store Media Gallery
   */
  public removeStoreMedia(obj, storeId) {
    const url = this.cmoUrl + 'store/' + storeId + '/media_gallery/delete';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Update Store Media Gallery
   */
  public updateStoreMedia(obj, storeId, mediaId) {
    const url = this.cmoUrl + 'store/' + storeId + '/media_gallery/update/' + mediaId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Get User All Address
   */
  public getUserAllAddress() {
    const url = this.cmoUrl + 'user/addresses';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
  * Save user address
  */

  saveUserAddress(addressObj) {
    const url = this.cmoUrl + 'user/addresses';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, addressObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
        });
    });
  }

  /**
   * Update user address
   */
  updateUserAddress(addressValue, id) {
    const url = this.cmoUrl + 'user/addresses/update/' + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, addressValue)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
        });
    });
  }

  /**
   * Delete user Address
   */
  deleteUserAddress(id) {
    const url = this.cmoUrl + 'user/addresses/delete/' + id;
    const obj = {
      address_id: id,
    };
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
        });
    });
  }

  /**
   * Save FCM Token
   */
  saveFCMToken(obj) {
    const url = this.cmoUrl + 'user/firebase_token';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
          // Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
        });
    });
  }

  public placePOSOrder(obj, storeId) {
    const url = this.cmoUrl + 'store/stores/' + storeId + '/place_order';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public collectPOSPayments(storeId, orderId) {
    const url = this.cmoUrl + 'store/stores/' + storeId + '/collect_payment/' + orderId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, null)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public createPayment(obj, storeId) {
    const url = this.cmoUrl + 'store/stores/' + storeId + '/create_payment_intent';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * User Notification
   */
  public getAllNotifications(type?: string) {
    const url = this.cmoUrl + `notifications?type=${type || null}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getSingleNotifications(notificationId) {
    const url = this.cmoUrl + 'notifications/get/' + notificationId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public setAsReadNotifications(notificationId) {
    const url = this.cmoUrl + 'notifications/read/' + notificationId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getReadAllNotifications() {
    const url = this.cmoUrl + 'notifications/read_all';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public deleteNotifications(notificationId) {
    const url = this.cmoUrl + 'notifications/delete/' + notificationId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public deleteAllNotifications() {
    const url = this.cmoUrl + 'notifications/delete_all';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * menu imports
   */

  public allStoreImports(storeId: string, perPageData: number, pageNumber: number) {
    const url = this.cmoUrl + 'store/menu_items/' + storeId + '/imports?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public storeImportsById(storeId, importId) {
    const url = this.cmoUrl + 'store/menu_items/' + storeId + '/imports/' + importId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Stock Images
   */
  public getAllStockImages(perPageData, pageNumber) {
    const url = this.cmoUrl + 'stock_images/all?per_page=' + perPageData + '&page=' + pageNumber;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public getSingleStockImageDetail(stockImageId) {
    const url = this.cmoUrl + 'stock_images/details/' + stockImageId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  public filterStockImageDetail(stockImageObj, perPageData: number, pageNumber: number) {
    const url = this.cmoUrl + `stock_images/filter?per_page=${perPageData}&page=${pageNumber}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, stockImageObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Razor Delivery Connection
   */
  ngDeliveryConnectionStatus() {
    const url = this.cmoUrl + 'store/check_ngdel_status';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }


  ngDeliverySetUp() {
    const url = this.cmoUrl + 'store/setup_delivery';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  ngDeliveryRelink() {
    const url = this.cmoUrl + 'store/relink_delivery';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  ngDeliveryUnlink() {
    const url = this.cmoUrl + 'store/unlink_delivery';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Resent Verification link
   */
  resendEmailVerification(emailObj) {
    const url = this.cmoUrl + 'resend_verification_email';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, emailObj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Upload documents for store registration
   */

  uploadStoreDocuments(data: object) {
    const url = this.cmoUrl + 'store/stores/upload_store_document';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, data)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Refund Approval API
   */
  refundApproval(orderId) {
    const url = this.cmoUrl + 'orders/refund/' + orderId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, '')
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  refundApprovalRejected(orderId: string, obj: object) {
    const url = this.cmoUrl + 'orders/refund_rejected/' + orderId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Review API
   */
  getStoreReviews(storeId) {
    const url = this.cmoUrl + 'store/stores/' + storeId + '/reviews';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  async initializeStripeTerminal(storeId: string) {
    const StripeTerminal = await loadStripeTerminal();

    let fctFn: FetchConnectionTokenFn = async () => {
      const ctResponse: any = await this.fetchStripConnectionToken(storeId);
      return ctResponse.data;
    }

    const tProps: TerminalProps = {
      onFetchConnectionToken: fctFn,
      onUnexpectedReaderDisconnect: this.unexpectedDisconnect
    }

    this.helper.setTerminal(StripeTerminal.create(tProps));
  }

  unexpectedDisconnect(): any {
  }

  /**
   * Order Details
   */

  updateOrderDeliveryStatus(orderId: string) {
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(this.cmoUrl + `store/order/update_delivery_status/${orderId}`)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Contact API
   */

  getCMOContact() {
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(this.cmoUrl + 'contact')
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  //#region change password

  changeUserPassword(obj: object) {
    const url = this.cmoUrl + 'user/change_password';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  //#endregion

  //#region Analytics API

  getQuickAnalytics(obj: object) {
    const url = this.cmoUrl + 'analytics/stats';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  getTodaySnapshot(obj: object) {
    const url = this.cmoUrl + 'analytics/today_snapshot';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  getSalesAnalytics(obj: object) {
    const url = this.cmoUrl + 'analytics/sales_stats';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * Dashboard Weekly Orders Chart
   */
  getDashboardWeeklyOrders(filterObj) {
    const url = this.cmoUrl + 'analytics/weekly_orders';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, filterObj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  /**
   * Dashboard Monthly Orders Chart
   */
  getDashboardMonthlyOrders(filterObj) {
    const url = this.cmoUrl + 'analytics/monthly_orders';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, filterObj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getMonthlyRatingsAndQty(obj: object) {
    const url = this.cmoUrl + `analytics/monthly_rating_qty`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region Email Check API

  checkExistsEmail(obj) {
    const url = this.cmoUrl + 'store/stores/check_email';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region CMO Rider Subscriptions

  getSubscriptionPlans(store_id?: string) {
    const url = this.cmoUrl + `subscription_plans`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  checkSubscriptionCoupon(code: string) {
    const url = this.cmoUrl + 'store/coupons/' + code;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  createNewSubscription(storeId: string, subObj: any) {
    const url = this.cmoUrl + `store/${storeId}/subscriptions`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, subObj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public newSubscription(storeId: string, postObj: any) {
    const url = this.cmoUrl + `store/${storeId}/subscribe`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, postObj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getSubscriptionDetails(storeId: string) {
    const url = this.cmoUrl + `store/${storeId}/subscription_details`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getSubscriptionHistory(storeId: string) {
    const url = this.cmoUrl + `store/${storeId}/subscription/history`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getCardToken(storeId: string) {
    const url = this.cmoUrl + `store/${storeId}/subscription/card_token`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public updateSubscriptionDefaultCard(storeId: string, postObj: any) {
    const url = this.cmoUrl + `store/${storeId}/subscription/update_card`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, postObj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public removeStoreCard(storeId: string, postObj: any) {
    const url = this.cmoUrl + `store/${storeId}/subscription/remove_card`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, postObj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public unSubsPlan(storeId: string) {
    const url = this.cmoUrl + 'store/' + storeId + '/subscriptions/cancel';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public relinkSubscription(storeId: string) {
    const url = this.cmoUrl + `store/${storeId}/subscriptions/relink_subscription`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public uplinkSubscription(storeId: string) {
    const url = this.cmoUrl + `store/${storeId}/subscriptions/uplink_subscription`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region payment methods

  public getAllPaymentMethods(storeId: string, perPageData: number, pageNumber: number) {
    const url = this.cmoUrl + `store/${storeId}/payment_methods?per_page=${perPageData}&page=${pageNumber}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public addPaymentMethod(storeId: string, obj: object) {
    const url = this.cmoUrl + `store/${storeId}/payment_methods`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public updateCreditCard(storeId: string, obj: object) {
    const url = this.cmoUrl + `store/${storeId}/payment_methods/update_card`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public updateBankAccount(storeId: string, obj: object) {
    const url = this.cmoUrl + `store/${storeId}/payment_methods/update_account`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public verifyPaymentMethod(storeId: string, obj: object) {
    const url = this.cmoUrl + `store/${storeId}/payment_methods/verify_account`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public deletePaymentMethod(storeId: string, paymentId: string) {
    const url = this.cmoUrl + `store/${storeId}/payment_methods/delete/${paymentId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }


  //#endregion

  //#region update cost for two and Is Active flag

  public updateCFT(storeId: string, obj: object) {
    const url = this.cmoUrl + `store/stores/${storeId}/update_cost_for_two`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public updateStoreIsActiveFlag(storeId: string, obj: object) {
    const url = this.cmoUrl + `store/${storeId}/update_is_active`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  public exportMenuItemsToExcel(storeId: string) {
    const url = this.cmoUrl + `store/menu_items/${storeId}/export`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getMaintenanceMode() {
    let maintenanceCollection = this.firestore.collection('/globalSettings');
    return maintenanceCollection.valueChanges();
  }

  //#region Insights API

  public getOrderCounts(storeId: string, obj: object) {
    const url = this.cmoUrl + `reports/${storeId}/order_count`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getOrderTrends(storeId: string, obj: object) {
    const url = this.cmoUrl + `reports/${storeId}/order_trend`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getMenuItemAndReviews(storeId: string, obj: object) {
    const url = this.cmoUrl + `reports/${storeId}/item_and_reviews`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getFoodPrepartionTime(storeId: string, obj: object) {
    const url = this.cmoUrl + `reports/${storeId}/food_preparation`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getYearlyOrders(filterObj) {
    const url = this.cmoUrl + 'reports/yearly_orders';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, filterObj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getAvgOrderValue(filterObj: object) {
    const url = this.cmoUrl + 'reports/avg_orders';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, filterObj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getStatusWiseOrder(obj: object) {
    const url = this.cmoUrl + 'reports/order_status_wise';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getMenuFoodReport(obj: object) {
    const url = this.cmoUrl + 'reports/menu_food_report';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getOrdersReport(obj: object) {
    const url = this.cmoUrl + 'reports/orders_report';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getHourlyDemandReport(obj: object) {
    const url = this.cmoUrl + 'reports/hourly_demand';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getUnderReviewData(storeId: string) {
    const url = this.cmoUrl + `store/${storeId}/under_review`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region ticket API

  public saveUserTicket(obj: object) {
    const url = this.cmoUrl + `user-tickets/ticket/save`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public getUserTicket(perPageData: number, pageNumber: number) {
    const url = this.cmoUrl + `user-tickets/ticket/get?per_page=${perPageData}&page=${pageNumber}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public getSingleTicketUsingExternal(ticketId: any) {
    const url = this.cmoTicketUrl + `api/v1/ticket/${ticketId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGetTicket(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public createTicketUsingExternal(obj: object) {
    const url = this.cmoTicketUrl + `api/v1/ticket`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPostTicket(url, obj).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public ticketThreadUsingExternal(ticketId: any, obj: object) {
    const url = this.cmoTicketUrl + `api/v1/ticket/${ticketId}/thread`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPostTicket(url, obj).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  //#endregion

  //#region sub domain API

  updateSubDomain(storeId: string, obj: object) {
    const url = this.cmoUrl + `store/sub-domain/${storeId}/update`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  deleteSubDomain(storeId: string) {
    const url = this.cmoUrl + `store/sub-domain/${storeId}/delete`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, {})
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  checkExistsSubDomain(obj) {
    const url = this.cmoUrl + 'store/sub-domain/check_sub_domain';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region apt per hours API

  updateAvgPrepTimePerHours(storeId: string, obj: object) {
    const url = this.cmoUrl + `store/stores/update_apt_per_hours/${storeId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  public updateOrderETA(orderId: string, obj: object) {
    const url = this.cmoUrl + `orders/update_eta/${orderId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public getCMORiderVehicleList() {
    const url = this.cmoRiderUrl + `getSupportedVehicleTypes`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGetTicket(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  //#region order's item refund, cancel, update API

  public refundOrderSingleItem(orderId: string, product_id: string) {
    const url = this.cmoUrl + `orders/${orderId}/single_item_refund/${product_id}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public cancelEntireOrder(orderId: string, obj: object) {
    const url = this.cmoUrl + `orders/cancel_order/${orderId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  public updateUserAlternativeItem(orderId: string, product_id: string, obj: object) {
    const url = this.cmoUrl + `orders/${orderId}/user_alternative_item/${product_id}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(res => {
          resolve(res);
        }, err => {
          reject(err);
        });
    });
  }

  //#endregion

  //#region pause delivery

  public updatePauseDelivery(storeId: string, obj: object) {
    const url = this.cmoUrl + `store/${storeId}/pause_delivery`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public cancelPauseDelivery(storeId: string) {
    const url = this.cmoUrl + `store/${storeId}/cancel_pause_delivery`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  //#endregion

  //#region Token

  public updateRefreshToken(obj: object) {
    const url = this.cmoUrl + `user/refresh_token`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  //#endregion

  //#region Kiosk APIs

  public addKioskDevice(store_id: string, data) {
    const url = `${this.cmoUrl}kiosk/${store_id}/add-device`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, data).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public verifyKioskDevice(store_id: string, kiosk_id: string, data) {
    const url = `${this.cmoUrl}kiosk/${store_id}/verify-device/${kiosk_id}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, data).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public removeKioskDevice(store_id: string, kiosk_id: string) {
    const url = `${this.cmoUrl}kiosk/${store_id}/remove/${kiosk_id}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public getKioskDevices(store_id: string, activeStatus: string) {
    const url = `${this.cmoUrl}kiosk/${store_id}/devices/${activeStatus}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public filterKioskDevice(data, perPage, pageNumber) {
    const url = `${this.cmoUrl}kiosk//filter/?per_page=${perPage}&page=${pageNumber}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, data).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public getKioskDeviceDetails(kiosk_id: string) {
    const url = `${this.cmoUrl}kiosk/${kiosk_id}/device`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public updateKioskName(store_id, kiosk_id, data) {
    const url = `${this.cmoUrl}kiosk/${store_id}/update-device/${kiosk_id}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, data).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public updateKioskLogo(store_id, data) {
    const url = `${this.cmoUrl}kiosk/update_logo/${store_id}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, data).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public updateKioskScreensaver(store_id, data) {
    const url = `${this.cmoUrl}kiosk/update_screen_saver/${store_id}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, data).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public removeKioskLogo(store_id: string) {
    const url = `${this.cmoUrl}kiosk/remove_logo/${store_id}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public removeKioskScreensaver(store_id: string) {
    const url = `${this.cmoUrl}kiosk/remove_screen_saver/${store_id}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public setCashPayment(store_id: string, option: boolean) {
    const url = `${this.cmoUrl}kiosk/${store_id}/accept-cash/${option}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public getKioskOrders(store_id: string) {
    const url = `${this.cmoUrl}kiosk/${store_id}/orders`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public getCashPaidOrder(store_id: string, order_id: string) {
    const url = `${this.cmoUrl}kiosk/${store_id}/paid/${order_id}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public getKioskOrderDetails(order_id: string) {
    const url = `${this.cmoUrl}kiosk/order/${order_id}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  public onCashPaid(store_id: string, order_id: string) {
    const url = `${this.cmoUrl}kiosk/${store_id}/paid/${order_id}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  //#endregion
  //#region Razor Delivery Api
  public updateRazorDelApiKey(obj: object) {
    const url = this.cmoUrl + `/store/razor_delivery/api_key`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }
  //#endregion

  //#region Timezone

  public getTimezoneList() {
    const url = `${this.cmoUrl}listings/timezones`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url).then(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  //#endregion

  //#region Timezone

  /**
   * add menu v2
   * @param storeId string
   * @param obj
   */

  public addStoreMenuV2(storeId, obj) {
    const url = this.cmoUrl + `store/store_menus/v2/${storeId}/add`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /*
  * @param storeId string
  * @param menuId string
  * @param obj
  */
  public updateStoreMenuV2(storeId, menuId, obj) {
    const url = this.cmoUrl + `store/store_menus/v2/${storeId}/update/` + menuId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /**
   * get all menu list
   * @param storeId
   * @param page
   * @param per_page
   */

  public getMenulistv2(storeId: string, page, per_page) {
    const url = this.cmoUrl + `store/store_menus/v2/${storeId}/all?page=${page}&per_page=${per_page}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * get single menu details
   * @param storeId
   * @param menuId
   */
  public getSingleMenudetailv2(storeId: string, menuId: string) {
    const url = this.cmoUrl + 'store/store_menus/v2/' + storeId + '/single/' + menuId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * delete single menu v2
   * @param storeId
   * @param menuId
   */
  public deleteSingleMenu(storeId: string, menuId: string) {
    const url = this.cmoUrl + 'store/store_menus/v2/' + storeId + '/remove/' + menuId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * create category V2
   * @param storeId
   * @param menuId
   * @param obj
   */

  public addStoreCategoryV2(storeId, menuId, obj) {
    const url = this.cmoUrl + `store/store_menus/v2/${storeId}/menu/${menuId}/add`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /**
   * filter menus V2
   * @param page
   * @param per_page
   */
  public filterItemsV2(obj: object, page, per_page) {
    const url = this.cmoUrl + `store/menu_items/v2/filter_menus?page=${page}&per_page=${per_page}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err?.error?.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /*
  update menu v2
   * @param storeId string
   * @param menuId string
   * @param categoryId string
   * @param obj
   */
  // store/store_menus/v2/:store_id/menu/:menu_id/update/:category_id
  public updateStoreCategoryV2(storeId, menuId, categoryId, obj) {
    const url = this.cmoUrl + `store/store_menus/v2/${storeId}/menu/${menuId}/update/` + categoryId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /**
   * get single menu category v2
   * @param storeId string
   * @param menuId string
   * @param categoryId string
   */
  public getSingleCategorydetailv2(storeId, menuId, categoryId) {
    const url = this.cmoUrl + `store/store_menus/v2/${storeId}/menu/${menuId}/category/` + categoryId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * delete single category v2
   * @param storeId
   * @param menuId
   * @param categoryId
   */
  public deleteSingleCategory(storeId: string, menuId: string, categoryId: string) {
    const url = this.cmoUrl + 'store/store_menus/v2/' + storeId + '/menu/' + menuId + '/remove/' + categoryId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * create product v2
   * @param storeId
   */

  public addProductV2(storeId: any, obj: any) {
    const url = this.cmoUrl + 'store/menu_items/v2/' + storeId + '/add';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * get all items v2 of store
   * @param storeId
   * @param page
   * @param per_page
   */

  public getAllStoreItems(storeId: any, page, per_page) {
    const url = this.cmoUrl + `store/menu_items/v2/${storeId}/all?page=${page}&per_page=${per_page}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * get single item details v2
   * @param storeID
   * @param itemId
   */

  public getSingleItemDetailsV2(storeId: any, itemId: any) {
    const url = this.cmoUrl + `store/menu_items/v2/${storeId}/single/${itemId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /*
  update product v2
   * @param storeId string
   * @param itemId string
   * @param obj
   */
  public updateStoreProductV2(storeId, itemId, obj) {
    const url = this.cmoUrl + `store/menu_items/v2/${storeId}/update/${itemId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /**
   * delete single item v2
   * @param storeId
   * @param itemId
   */
  public deleteSingleProductV2(storeId: string, itemId: string) {
    const url = this.cmoUrl + 'store/menu_items/v2/' + storeId + '/remove/' + itemId;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * @param storeId
   */
  public addToAnotherMenuV2(storeId, obj) {
    const url = this.cmoUrl + `store/menu_items/v2/${storeId}/add_to_menu/all`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /**
   * @param storeId
   * @param itemId
   * @param menuid
   */
  public updateItemToAnotherMenuV2(storeId, itemId, menuId, obj) {
    const url = this.cmoUrl + `store/menu_items/v2/${storeId}/add_to_menu/${itemId}/update/${menuId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, obj).then(
        (data) => {
          resolve(data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /**
   * @param storeId
   * @param itemId
   * @param menuId
   */
  public getSingleLinkedItemV2(storeId,menuId,itemId) {
    const url = this.cmoUrl + `store/menu_items/v2/${storeId}/menu/${menuId}/single/${itemId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * @param storeId
   * @param itemId
   * @param menuid
   * @param categoryId
   */
  public deleteLinkedItemV2(storeId,itemId,menuId,categoryId) {
    const url = this.cmoUrl + `store/menu_items/v2/${storeId}/add_to_menu/${itemId}/delete/${menuId}/${categoryId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }

  /**
   * @param storeId
   * @param itemId
   * @param menuid
   */
   public deleteLinkedItemFromMenuV2(storeId,itemId,menuId) {
    const url = this.cmoUrl + `store/menu_items/v2/${storeId}/add_to_menu/${itemId}/delete/${menuId}`;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          Bugsnag.notify(new Error(err.error.message)); // Bugsnag error handle
          reject(err);
        });
    });
  }
}
