import { Component, OnDestroy, OnInit, ChangeDetectorRef, TemplateRef, ViewChild } from '@angular/core';
import {
  NbMediaBreakpointsService, NbMenuService, NbThemeService, NbToastrService, NbDialogService,
  NbMenuItem,
  NbSidebarService,
  NbPopoverDirective,
} from '@nebular/theme';

import { map, takeUntil, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NbAuthService } from '@nebular/auth';
import { CmoapiService } from '../../../services/cmoapi.service';
import { HelperService } from '../../../services/helper.service';
import { ChatService } from '../../../services/chat.service';
import { Router, Scroll } from '@angular/router';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { mergeMap } from 'rxjs/operators';
import { MessageService } from '../../../services/message.service';
import { ConstantProviderService } from '../../../providers/constant-provider.service';
import { LayoutService } from '../../../@core/utils';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})

export class HeaderComponent implements OnInit, OnDestroy {

  //#region variables

  private destroy$: Subject<void> = new Subject<void>();

  public user: any;
  public stores: any;
  public storeId: any;
  public payAmount: any;
  public payDesc: any;
  public selectedSidebarMenuItem: any[] = [];

  public themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  public currentTheme = 'default';

  public userMenu = [{ title: 'My Profile' }, { title: 'Sign Out' }];

  public quickActionItems: NbMenuItem[] = [
    {
      icon: 'list-outline',
      title: 'Menu Editor',
      link: 'menu-editor',
    },
    {
      icon: 'film-outline',
      title: 'Media Gallery',
      link: 'media-gallery'
    },
    {
      icon: 'car-outline',
      title: 'Delivery Setup',
      link: 'delivery-setup/plan-setup',
      hidden: localStorage.getItem('selectedUserRole') != 'storeowner' ? true : false
    },
    {
      icon: 'phone-outline',
      title: 'Help & Support',
      link: 'help-support'
    }
  ]

  public notificationList: any = [];
  public dialogRefs: any[] = [];

  @ViewChild('processingRef') processingDialogRef: TemplateRef<any>;
  @ViewChild('captureingRef') captureingDialogRef: TemplateRef<any>;
  @ViewChild(NbPopoverDirective) notificationPopover: NbPopoverDirective;

  public spinnerMessage = 'Payment Processing...';
  public sidebarState = 'expanded';

  terminal: any;
  paymentIntentObj: any;
  paymentIntentId: any;
  discoveredReaders: any;
  public totalUnReadNotificationCount: any;

  public isProcessing = false;
  public isCapturing = false;
  public userPictureOnly = false;
  public loading = false;
  public isStoreDropDown: boolean;
  public amountLength: boolean;

  public userRole: string;

  //#endregion

  //#region life cycle hook

  constructor(
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: NbAuthService,
    private cmoApiService: CmoapiService,
    private cdr: ChangeDetectorRef,
    private helper: HelperService,
    private chatService: ChatService,
    private router: Router,
    private afMessaging: AngularFireMessaging,
    private tosterService: NbToastrService,
    private dialogService: NbDialogService,
    private messageService: MessageService,
    private sidebarService: NbSidebarService,
    private layoutService: LayoutService,
    public constant: ConstantProviderService
  ) {
    this.storeId = this.helper.getLocalStore();
    this.userRole = localStorage.getItem('selectedUserRole');
    this.messageService.getMessage().subscribe((message: any) => {
      if (message == 'Store-add') {
        this.helper.removeAllStoresList();
        this.getStores();
      
      } else if (message == 'Notification') { 
        this.getNotifications();
      
      } else if (message == 'profileUpdate') {
        this.getUserProfile();
      }
    });

    /**
     * when site init 1st time
     */
    const routeFlag = this.helper.checkRouteForStoreDropdown(window.location.pathname);
    if (routeFlag == true) this.isStoreDropDown = false;
    else this.isStoreDropDown = true;

    /**
     * when route changes
     */
    this.router.events.subscribe((data: any) => {
      if (data instanceof Scroll) {

        // close notification popup
        if (this.notificationPopover) this.notificationPopover.hide();

        const routeFlag = this.helper.checkRouteForStoreDropdown(data.routerEvent.url);
        if (routeFlag == true) this.isStoreDropDown = false;
        else this.isStoreDropDown = true;
      }
    });

    this.menuService.onItemClick().subscribe((data: any) => {
      if (data?.item?.title == 'Sign Out') this.logout();
      this.toggleSidebar();
      // if (window.innerWidth < 961) {
      //   this.sidebarService.toggle(true, 'menu-sidebar');
      //   this.layoutService.changeLayoutSize();
      // }
    });

    // save current selected sidebar menu
    this.menuService.onItemSelect().subscribe((data: any) => {
      this.selectedSidebarMenuItem.push(data?.item);
    });
  }

  ngOnInit() {
    this.getStores();
    this.currentTheme = this.themeService.currentTheme;
    // this.getUserProfile();
    this.getNotifications();

    const { xl } = this.breakpointService.getBreakpointsMap();
    // this.themeService.onMediaQueryChange()
    //   .pipe(
    //     map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
    //     takeUntil(this.destroy$),
    //   )
    //   .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag == 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => this.redirect(title),

    );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.dialogRefs?.length > 0) {
      this.dialogRefs.forEach((list: any) => { list.close() });
    }
  }

  //#endregion

  //#region private methods

  private async getUserProfile() {
    this.cmoApiService.getUserProfile().then((res: any) => {
      this.user = res.data;

      localStorage.setItem('loggedInUser', JSON.stringify(this.user._id));
      localStorage.setItem('loggedInUserDetails', JSON.stringify(this.user));
      const store = localStorage.getItem('selectedStore');
      this.helper.loggedInUserDetails = this.user;
      this.helper.loggedInUserRole = this.user.roles[0];

      if (store) {
        this.helper.selectedStoreId = this.helper.getLocalStore();
        this.storeId = this.helper.getLocalStore();
        if (this.stores?.length) {
          const storeIdList = this.stores.map((store: any) => { return store._id });
          if (!storeIdList.includes(this.storeId)) this.storeChange(this.user?.stores[0])
        }

      } else if (!store) {
        if (this.user?.stores?.length && this.stores?.length) {
          this.storeChange(this.user?.stores[0]);
        }

        // } else {
        //   this.router.navigate(['pages', 'anonymous-user']);
        // }
      }

      this.cdr.detectChanges();

    }, (error: any) => {
      const errMsg = this.helper.getResponseErrorMessage(error);
      if (errMsg?.length) {
        this.tosterService.danger(errMsg, this.constant.ERROR);
      }
    });

    this.initialize();
    this.cdr.detectChanges();
  }

  private getStores() {
    const stores = this.helper.getAllStoresList();
    if (stores == undefined || stores == null || !stores.length) {
      this.cmoApiService.getAllStores().then((res: any) => {
        this.helper.setAllStoresList(res.data);
        this.getAllStores(res.data);
  
      }, (error: any) => {
        const errMsg = this.helper.getResponseErrorMessage(error);
        if (errMsg?.length) {
          this.tosterService.danger(errMsg, this.constant.ERROR);
        }
      });

    } else {
      this.getAllStores(stores);
    }
  }

  private getAllStores(data: any) {
    const result: any = data;
    this.stores = this.sortArray(result);
    this.getUserProfile();
    this.cdr.detectChanges();
  }

  private getNotifications() {
    this.cmoApiService.getAllNotifications('unread').then((res: any) => {
      const notificationList: any = res.data;
      this.notificationList = notificationList.slice(0, 50);
      const urReadCount = res.data.filter((list: any) => { return list.read == false });
      this.totalUnReadNotificationCount = urReadCount.length ? urReadCount.length : 0;

    }, (error: any) => {
      const errMsg = this.helper.getResponseErrorMessage(error);
      if (errMsg?.length) {
        this.tosterService.danger(errMsg, this.constant.ERROR);
      }
    });
  }

  private sortArray(array) {
    return array.sort((a: any, b: any) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1);
  }

  private async initialize() {
    await this.chatService.initiateChatClients();
  }

  private deleteFCMToken() {
    this.afMessaging.getToken
      .pipe(mergeMap(token => this.afMessaging.deleteToken(token)))
      .subscribe(
        (token) => { },
      );
  }

  private async collectStripPayment(clientSecret: any) {
    const $terminal = this.terminal;
    let self = this;
    let processRef = this.dialogService.open(this.processingDialogRef, { closeOnBackdropClick: false });
    this.dialogRefs.push(processRef);

    this.loading = true;
    this.isProcessing = true;

    $terminal.collectPaymentMethod(clientSecret).then(function (result) {
      if (result.error) {

      } else {
        self.paymentIntentObj = result.paymentIntent;
        (async () => {
          const payProcess = await self.processPayment(result.paymentIntent, processRef);
        })();
      }
    });
  }

  private async processPayment(piObj: any, processRef: any) {
    processRef.close();
    let self = this;
    let $terminal = this.terminal;

    this.loading = false;
    this.isProcessing = false;
    const captureRef = this.dialogService.open(this.captureingDialogRef, { closeOnBackdropClick: false });
    this.dialogRefs.push(captureRef);

    this.isCapturing = true;
    this.spinnerMessage = 'Payment Capturing...';

    const processPObj = await $terminal.processPayment(piObj);
    this.loading = true;
    if (processPObj.error) {
      return processPObj.error;

    } else if (processPObj.paymentIntent) {
      self.paymentIntentId = processPObj.paymentIntent.id;
      (async () => {
        const capturePayment = await self.capture(processPObj.paymentIntent.id);
        this.loading = false;
        this.payAmount = null;
        this.payDesc = '';
        this.amountLength = true;
        captureRef.close();
        this.tosterService.success(this.constant.ORDER_PLACED, this.constant.SUCCESS);
        this.router.navigate(['pages', 'orders', 'all', 'list']);
      })();
    }
  }

  private async capture(paymentIntentId) {
    const piObj = {
      'id': paymentIntentId
    }
    const capturePaymentInt = await this.cmoApiService.capturePaymentIntent(piObj, this.storeId);
    return capturePaymentInt;
  }

  //#endregion

  //#region public methods

  public changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  public toggleSidebar() {
    // for responsive only
    if (window.innerWidth < 961) {
      this.sidebarService.getSidebarState('menu-sidebar').subscribe((state: any) => {
        this.sidebarState = state;
      });
      this.sidebarService.toggle(true, 'menu-sidebar');
      // this.layoutService.changeLayoutSize();
    }
  }

  public getWindowInnerWidth() {
    return window.innerWidth;
  }

  public redirect(title: any) {
    if (title == 'My Profile') {
      if (this.selectedSidebarMenuItem?.length > 0) {
        this.selectedSidebarMenuItem.forEach((list: any) => {
          if (list.selected) {
            list.selected = false;
          }
        });
        this.selectedSidebarMenuItem = []
      }
      this.router.navigate(['pages', 'profile']);
    }
    else if (title == 'Sign Out') this.logout();
  }

  public navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

  public moreNotification() {
    this.notificationPopover.hide();
    this.router.navigate(['pages', 'notification']);
  }

  public deleteNotification(id: any) {
    this.cmoApiService.deleteNotifications(id).then((res: any) => {
      this.getNotifications();
      this.tosterService.success(res.message, this.constant.SUCCESS);

    }, (error: any) => {
      const errMsg = this.helper.getResponseErrorMessage(error);
      if (errMsg?.length) {
        this.tosterService.danger(errMsg, this.constant.ERROR);
      }
    });
  }

  public markAsReadNotification(id: any) {
    this.cmoApiService.setAsReadNotifications(id).then((res: any) => {
      this.getNotifications();
      this.tosterService.success(res.message, this.constant.SUCCESS);

    }, (error: any) => {
      const errMsg = this.helper.getResponseErrorMessage(error);
      if (errMsg?.length) {
        this.tosterService.danger(errMsg, this.constant.ERROR);
      }
    });
  }

  public async storeChange(value: any, url?: string) {
    this.storeId = value;
    const filterStore = this.stores.filter((list: any) => { return list._id == value });
    this.helper.setStore(filterStore[0]);
    await this.initialize();
    location.reload();
    // this.helper.refreshComponent(url ? url : window.location.pathname);
    this.cdr.detectChanges();
  }

  public getUserName() {
    if (this.user !== null && this.user !== '' && this.user !== undefined) {
      return this.user.firstname + ' ' + this.user.lastname;
    } else {
      return '';
    }
  }

  public checkAmountLength() {
    if (this.payAmount > 1000) {
      this.amountLength = true;
      this.tosterService.danger(this.constant.AMOUNT_LESS_THAN_1000, this.constant.ERROR);

    } else if (this.payAmount < 0.5 || this.payAmount === null || this.payAmount === undefined ||
      this.payAmount.includes('-')) {
      this.amountLength = true;
      this.tosterService.danger(this.constant.AMOUNT_GREATER_5_CENT, this.constant.ERROR);

    } else this.amountLength = false;
  }

  public logout() {
    this.chatService.unsubscribeAllChannel();
    if (window.location.protocol === 'https:') {
      this.deleteFCMToken();
    }
    this.helper.clearLocalStorage();
    this.authService.logout('email');
    window.location.replace('/auth/login');
  }

  public collectPayment(paymentRef: TemplateRef<any>) {
    const selectedReader = this.helper.getConnectedDevice();
    if (selectedReader !== undefined && selectedReader !== null) {
      const ref1 = this.dialogService.open(paymentRef);
      this.dialogRefs.push(ref1);
    
    } else {
      this.tosterService.danger(this.constant.ESTABLISHED_CONNECTION, this.constant.ERROR);
      this.router.navigate(['pages', 'stripe-terminal'])
    }
  }

  public async collectDirectPayment(ref: any) {
    this.terminal = this.helper.getTerminal();

    const obj = {
      amount: this.payAmount,
      description: this.payDesc,
    };

    if (this.payAmount === null || this.payAmount === undefined) {
      this.amountLength = true;
      this.tosterService.danger(this.constant.AMOUNT_GREATER_5_CENT, this.constant.ERROR);

    } else {
      const clientSecret = await this.cmoApiService.createPayment(obj, this.storeId).then((data: any) => {
        ref.close();
        return data['client_secret'];

      }, (err: any) => {
        const errMsg = this.helper.getResponseErrorMessage(err);
        this.tosterService.danger(errMsg, this.constant.ERROR);
      });

      if (clientSecret) this.collectStripPayment(clientSecret);
    }

  }

  public getNotificationTime(time: any) {
    return this.helper.convertToTimestamp(time)
  }

  public onClickResponsiveUser() {
    this.toggleSidebar();
    this.router.navigate(['pages', 'profile']);
  }

  public onClickNotification() {
    this.notificationPopover.show();
    if (this.notificationList?.length) {
      this.cmoApiService.getReadAllNotifications().then((res: any) => {
        // this.toastr.success(this.constant.NOTIFICATION_ALL_MARK ,this.constant.SUCCESS);
        setTimeout(() => {
          this.getNotifications();
        }, 10000);

      }, (error: any) => {
        const errMsg = this.helper.getResponseErrorMessage(error);
        if (errMsg?.length) {
          if (errMsg?.length) {
        }
          this.tosterService.danger(errMsg, this.constant.ERROR);
        }
      });
    }
  }

  public responsiveHeaderNevigation(page: string) {
    if (page == 'pos') this.router.navigate(['pages', 'store-pos']);
    else if (page == 'chat') this.chatService.openChat();
  }

  //#endregion
}
