import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { tap } from 'rxjs/operators';
import { NbTokenService } from '@nebular/auth';

@Injectable()
export class AuthGuard implements CanActivate {

    user = {};

    constructor(
        private authService: NbAuthService,
        private router: Router,
        private tokens: NbTokenService
    ) {
    }

    canActivate() {
        return this.authService.isAuthenticated()
            .pipe(
                tap(authenticated => {
                    this.authService.onTokenChange()
                .subscribe((token: NbAuthJWTToken) => {
          
                    if (token.isValid()) {
                        this.user = token;
                        // this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
                        localStorage.setItem('selectedUserRole', this.user['payload'].roles[0]);
                    }
          
                });
                    if (!authenticated) {
                        this.router.navigate(['auth', 'login']);
                    }
                }),
            );
    }
}
