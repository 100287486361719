import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse,
         HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';


import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { NbToastrService } from '@nebular/theme';
import { ConstantProviderService } from '../providers/constant-provider.service';

@Injectable()
export class InterceptedHttp implements HttpInterceptor {
    constructor(
        private router: Router,
        private auth: NbAuthService,
        private toster: NbToastrService,
        public constant: ConstantProviderService,
    ) { }
    static requestCount: number = 0;
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf('auth/login') === -1) {
            // Get the auth token from the service.
            let authToken = '';
            this.auth.getToken()
                .subscribe((atoken: NbAuthJWTToken) => {
                    authToken = atoken['token'];
                });
            // Clone the request and set the new header in one step.
            let authReq: any;
            if (req.url.includes('support.chatmyorder.com')) {
                authReq = req.clone({ headers: req.headers.append('Authorization', 'Basic Q61ENLRMDN2GTO4AS86HVS9QJYW7NUJEWNXGURGPUTPKBOSNBNADZLP300AXZMBH') });
            } else {
                authReq = req.clone({ headers: req.headers.append('Authorization', 'Bearer ' + authToken) });
            }
            InterceptedHttp.requestCount++;
            // send cloned request with header to the next handler.
            return next.handle(authReq)
                .pipe(
                    map((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {

                        }
                        return event;
                    }),
                    catchError((error: HttpErrorResponse) => {
                        if (error.status == 401) {
                            this.toster.danger(this.constant.SESSION_EXPIRED_MSG, this.constant.SESSION_EXPIRED_TITLE);
                            this.auth.logout('email');
                            this.router.navigate(['auth', 'login']);
                        }
                        return throwError(error);
                    }));
        } else {
            const _req = req.clone({ setHeaders: { 'Content-Type': 'application/x-www-form-urlencoded' } });
            return next.handle(_req);
        }
    }



}
