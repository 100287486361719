import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Client } from 'twilio-chat';
import { Channel } from 'twilio-chat/lib/channel';

import { CmoapiService } from './cmoapi.service';
import { Message } from 'twilio-chat/lib/message';
import { NbToastrService, NbWindowRef, NbWindowService, NbMenuService } from '@nebular/theme';
import { HelperService } from './helper.service';
import * as Pubnub from 'pubnub';
import { Subject } from 'rxjs';
import { ChatComponent } from '../pages/chat/chat.component';
import { ConstantProviderService } from '../providers/constant-provider.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ChatService {

  //#region variables

  public CMO_CHAT_CLIENTS: CMOChatClient[] = [];
  public ACCESS_TOKENS: string[] = [];
  public CLIENTS: Client[] = [];
  public CHANNELS: Channel[] = [];
  public IDENTITIES: string[] = [];
  
  public pubnub: any;
  
  public is_initializing: boolean = false;
  public pnChannelSub: string = null;
  public chatChannel: string = '285eb2d8-9faa-46ff-bef6-db08fe5bca80.*';
  
  public currentStore = this.helper.getLocalStore();
  
  public currentStoreUid = null;
  public activeChannel = null;
  
  public receiveMessageSubscription = new Subject<any>();

  private windowDialog: NbWindowRef;

  //#endregion

  //#region life cycle hook

  constructor(
    private helper: HelperService,
    private toastrService: NbToastrService,
    private windowService: NbWindowService,
    private menuService: NbMenuService,
    public constant: ConstantProviderService,
  ) {
    this.pubnub = new Pubnub({
      publishKey: environment.pubnub.pk,
      subscribeKey: environment.pubnub.sk,
      uuid: this.helper.getLocalStoreDetails()?.uid,
    });
    this.subscribePubnubEvents();

    this.menuService.onItemClick().subscribe((data: any) => {
      if (data?.item.title == 'Customer Chats') {
        if (this.windowDialog) this.windowDialog.close();
        this.openChat();
      }
    });
  }

  // public async initiateChatClients() {
  //   this.is_initializing = true;
  //   return new Promise((resolve, reject) => {
  //     // first get list of stores of logged in user
  //     // this.API.getAllStores().then(async (stores: any[]) => {
  //     const stores = [this.helper.getLocalStore()];
  //     // make an array of store ids as identities
  //     this.IDENTITIES = stores.map((store: any) => {
  //       return store;
  //     });
  //     this.http.post(environment.CMO_API_URL + 'chat/access_tokens', { identities: this.IDENTITIES })
  //       .toPromise().then(async (res: any) => {
  //         // create chat clients
  //         for (const token of res.data.access_tokens) {
  //           const client = await Client.create(token.access_token);
  //           this.CMO_CHAT_CLIENTS.push({
  //             access_token: token.access_token,
  //             identity: token.identity,
  //             client: client,
  //             channels: [],
  //           });
  //         }
  //         this.is_initializing = false;
  //         // this.getClientChannels();
  //         resolve(this.CMO_CHAT_CLIENTS);
  //       }).catch((err) => {
  //         this.is_initializing = false;
  //         reject(err);
  //       });
  //     // }, (err) => {
  //     //   this.is_initializing = false;
  //     //   reject(err);
  //     // });
  //   });
  // }

  public setActiveChannel(channel) {
    this.activeChannel = channel;
  }

  public async initiateChatClients() {
    // this.is_initializing = true;
    // return new Promise((resolve, reject) => {
    //   // first get list of stores of logged in user
    //   this.API.getAllStores().then(async (stores: any[]) => {
    //     if (stores && stores.length > 0) {
    //       stores.forEach((store) => {
    //         this.currentStoreUid = store._id === this.currentStore ? store.uid : null;
    //         let pnChannelSub = store['uid'] + '.*';
    //         this.subscribeChatChannel(pnChannelSub);
    //         this.getChannelMemberships(store['uid']);
    //       });
    //     }
    //   });
    //   const stores = [this.currentStore];
    //   // make an array of store ids as identities
    //   this.IDENTITIES = stores.map((store: any) => {
    //     return store;
    //   });
    //   this.http.post(environment.CMO_API_URL + 'chat/access_tokens', { identities: this.IDENTITIES })
    //     .toPromise().then(async (res: any) => {
    //       // get FCM Token
    //       this.afMessaging.requestToken.subscribe(async (fcmToken) => {
    //         this.FCM_TOKEN = fcmToken;
    //         this.initChatClients(res.data.access_tokens);
    //         this.afMessaging.messages
    //       }, (err) => {
    //       });

    //       // create chat clients
    //       for (const token of res.data.access_tokens) {
    //         const client = await Client.create(token.access_token);
    //         client.setPushRegistrationId('fcm', this.FCM_TOKEN);
    //         this.CMO_CHAT_CLIENTS.push({
    //           access_token: token.access_token,
    //           identity: token.identity,
    //           client: client,
    //           channels: [],
    //         });
    //       }
    //       this.is_initializing = false;
    //       resolve(this.CMO_CHAT_CLIENTS);
    //     }).catch((err) => {
    //       this.is_initializing = false;
    //       reject(err);
    //     });
    // });
  }

  // public getClientChannels() {
  //   if (this.CMO_CHAT_CLIENTS.length) {
  //     this.CMO_CHAT_CLIENTS.forEach((CCL) => {
  //       // add all user channels
  //       CCL.client.getUserChannelDescriptors().then(async (chDesc) => {
  //         for (const chD of chDesc.items) {
  //           const subChannel = await chD.getChannel();
  //           if (subChannel.status !== 'joined') {
  //             subChannel.join();
  //           }
  //           CCL.channels.push({ channel: subChannel });
  //         }
  //         for (const chnl of CCL.channels) {
  //           const msgs = await chnl.channel.getMessages();
  //           chnl.messages = msgs.items;

  //           // subscribe to new message
  //           chnl.channel.on('messageAdded', (msg) => {
  //             chnl.messages.push(msg);
  //           });
  //         }
  //       });

  //       // subscribe to new channels
  //       CCL.client.on('channelInvited', (invitedChnl) => {
  //         CCL.channels.push({ channel: invitedChnl });
  //       });
  //     });
  //   }
  // }

  public resetCMOCleint() {
    this.CMO_CHAT_CLIENTS.forEach(ccl => {
      ccl.client.removeAllListeners();
    });
    this.CMO_CHAT_CLIENTS = [];
    this.is_initializing = false;
  }

  public unsubscribeAllChannel() {
    this.pubnub.unsubscribeAll();
  }

  /**
   * PUBNUB CHAT
   */
  subscribePubnubEvents() {
    this.pubnub.addListener({
      status: (st: any) => {
      },
      message: (msg: any) => {
        this.receiveMessageSubscription.next(msg);
        if (this.currentStoreUid !== msg.publisher && (!this.activeChannel || (this.activeChannel !== msg.channel))) {
          this.toastrService.primary('Message', 'You have unread message for ' + msg.userMetadata.store);
        }
        // this.chatMessages.push({
        //   message: msg.message,
        //   timestamp: moment(msg.timetoken / 10000000).format('hh:mm A'),
        //   personal: (msg.publisher == this.loggedInUserUid)
        // });
      },
    });
   // this.subscribeChatChannel();
  }

  subscribeChatChannel(pnChannelSub) {
    // subscribe to channel
    this.pubnub.subscribe({
      channels: [pnChannelSub],
    });
  }

  getChannelMemberships(storeId) {
    this.pubnub.objects.getMemberships({ uuid: storeId }).then((res) => {
    }, (err) => {
    });
  }

  public openChat() {
    const storeId = this.helper.getLocalStore();
    
    if (storeId !== 'undefined' && storeId !== null) {
      if (this.windowDialog == undefined) {
        localStorage.setItem('cmo-chat-box', 'open');
        this.windowDialog = this.windowService.open(ChatComponent, 
        {
          title: 'Customer Chat-Box',
        });
  
        this.windowDialog.onClose.subscribe((data: any) => {
          localStorage.removeItem('cmo-chat-box');
          this.windowDialog = undefined;
        });
      }

    } else {
      this.toastrService.warning(this.constant.SELECT_RESTAURANT_FIRST, this.constant.OOPS);
    }
  }

  public async getRestaurantChatCounts(uid: string) {
    let chatCount: number = 0;
    const members = await this.pubnub.objects.getMemberships({ uuid: uid || this.helper.getLocalStoreDetails().uid });
    if (members.data?.length) {
      const userIds = members.data.slice(0, 15).map((list: any) => { return list.channel.id });
      const countChannels = await this.pubnub.messageCounts({ channels: userIds, channelTimetokens: [localStorage.getItem('messageReadTime')] });
      
      chatCount = 0;
      for (let channel in countChannels.channels) {
        chatCount += countChannels.channels[channel]
      }
    }

    return chatCount;
    // return (chatCount > 0 && chatCount < 10) ? chatCount.toString() : (chatCount > 10) ? '9+' : '';
  }
}

export interface CMOChatClient {
  identity: string;
  access_token: string;
  client: Client;
  channels: {
    channel?: Channel;
    messages?: Message[];
    selected?: boolean;
  }[];
}
