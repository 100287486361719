import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout withScroll="false">
      <nb-layout-header fixed>
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" state="expanded" responsive="true" [right]="windowSize && windowSize < 961 ? true : false">
        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>

      <nb-layout-footer fixed>
        <ngx-footer></ngx-footer>
      </nb-layout-footer>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {

  //#region variables

  public windowSize: number;

  //#endregion

  constructor() {
    this.windowSize = window.innerWidth
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any) {
    if (event.target.innerWidth) {
      this.windowSize = event.target.innerWidth;
    }
  }
}
