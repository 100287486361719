<!-- Desktop header -->
<div class="container-fluid laptop">
  <div class="row show-grid" style="display: flex; justify-content: space-between;">
    <div class="col-xxl-4  header-container">
      <div class="h1_to">
        <a (click)="toggleSidebar()" class="sidebar-toggle"  *ngIf="(stores?.length > 0) && userRole">
          <nb-icon icon="menu-2-outline" style="line-height: 5px; font-size: 29px;"></nb-icon>
        </a>
        <a class="logo" [ngClass]="stores?.length > 0 ? 'logo-left-divider' : ''">CHAT MY ORDER / MERCHANT PORTAL</a>
      </div> 
    </div>
    <div class="col-xxl-8 header-container" style="justify-content:end;">
      <!-- <div *ngIf="stores?.length">
        <button nbButton status="primary" outline (click)="collectPayment(paymentRef)" class="block" size="small" [nbTooltip]="constant.COLLECT_PAYMENT" nbTooltipPlacement="bottom">
          {{constant.COLLECT_PAYMENT}}
        </button> 
      </div> -->
      <nb-actions size="small">
        <!-- <nb-action *ngIf="stores?.length && userRole !== 'kitchenstaff' && userRole != 'storestaff'">
          <a [nbPopover]="quickAction" nbPopoverTrigger="hover" nbPopoverPlacement="bottom" style="cursor: pointer;" [title]="constant.QUICK_ACTIONS">
            <nb-icon icon="flash-outline" status="danger"></nb-icon>
          </a>
        </nb-action> -->
    
        <nb-action *ngIf="userRole !== 'kitchenstaff' && userRole != 'storestaff'">
          <button [nbPopover]="notificationRef" [ngClass]="totalUnReadNotificationCount > 0 ? 'active_icon' : 'inactive_icon'" (click)="onClickNotification()" nbPopoverTrigger="noop" style="cursor: pointer;"  [title]="constant.NOTIFICATIONS" nbPopoverPlacement="bottom" >
            <nb-icon icon="bell-outline"></nb-icon>
          </button>
        </nb-action>
    
        <nb-action class="user-action" *nbIsGranted="['view', 'user']">
          <nb-user [nbContextMenu]="userMenu" [onlyPicture]="userPictureOnly" [name]="getUserName()"  color="#09d898"
            nbContextMenuTag="my-context-menu" [picture]="user?.profile_picture">
          </nb-user>
        </nb-action>
      </nb-actions>
    </div>
  </div>  
</div>

<!-- responsive header -->
<div class="container-fluid moblie">
  <div class="row show-grid" style="display: flex; justify-content: space-between;">
    <div class="col-xxl-8 col-8  header-container">
      <div class="h1_to">
        <nb-actions size="small" *ngIf="sidebarState == 'expanded' && getWindowInnerWidth() < 576">
      
            <nb-action *ngIf="stores?.length && userRole != 'kitchenstaff' && userRole != 'storestaff'">
              <a style="cursor: pointer;" [title]="constant.VIRTUAL_TERMINAL" (click)="responsiveHeaderNevigation('pos')">
                <nb-icon icon="monitor-outline"></nb-icon>
              </a>
            </nb-action>
  
            <nb-action *ngIf="stores?.length && userRole !== 'kitchenstaff' && userRole != 'storestaff'">
              <a style="cursor: pointer;"  [title]="constant.CUSTOMER_CHATS" (click)="responsiveHeaderNevigation('chat')">
                <nb-icon icon="message-circle-outline"></nb-icon>
              </a>
            </nb-action>
  
            <nb-action *ngIf="userRole !== 'kitchenstaff' && userRole != 'storestaff'">
              <a [nbPopover]="notificationRef" [ngClass]="totalUnReadNotificationCount > 0 ? 'active_icon' : ''" (click)="onClickNotification()" nbPopoverTrigger="noop" style="cursor: pointer;"  [title]="constant.NOTIFICATIONS" nbPopoverPlacement="bottom" >
                <nb-icon icon="bell-outline"></nb-icon>
              </a>
            </nb-action>
        </nb-actions>
        <nb-actions size="small" *ngIf="sidebarState == 'collapsed' || sidebarState == 'compacted'">
          <nb-action class="user-action" *nbIsGranted="['view', 'user']">
            <nb-user [onlyPicture]="userPictureOnly" [name]="getUserName()" nbContextMenuTag="my-context-menu" [picture]="user?.profile_picture" (click)="onClickResponsiveUser()"></nb-user>
          </nb-action>
        </nb-actions>
      </div> 
    </div>
    <div class="col-xxl-4 col-4 header-container" style="justify-content:end;">
      <!-- <div *ngIf="stores?.length">
        <button nbButton status="primary" outline (click)="collectPayment(paymentRef)" class="block" size="small" [nbTooltip]="constant.COLLECT_PAYMENT" nbTooltipPlacement="bottom">
          {{constant.COLLECT_PAYMENT}}
        </button> 
      </div> -->
     
      <a (click)="toggleSidebar()" class="sidebar-toggle">
        <nb-icon icon="menu-2-outline" style="font-size: 29px;"></nb-icon>
      </a>
    </div>
  </div>  
</div>

<!-- Collect Direct Payment Ref -->
<ng-template #paymentRef let-data let-ref="dialogRef">
  <nb-card>
      <nb-card-header>Collect Direct Payment</nb-card-header>
      <nb-card-body>
          <div class="col-md-12">
            <input type="text" nbInput fullWidth placeholder="Amount" [(ngModel)]="payAmount" (keyup)="checkAmountLength()">
          </div>
          <div class="col-md-12" style="margin-top: 10px;">
            <input type="text" nbInput fullWidth placeholder="Description" [(ngModel)]="payDesc">
          </div>
        
      </nb-card-body>
      <nb-card-footer>
          <button style="margin-left: 30%;" nbButton status="primary" (click)="collectDirectPayment(ref)" [disabled]="amountLength"
            [nbTooltip]="constant.COLLECT" nbTooltipPlacement="bottom">
            {{constant.COLLECT}}
          </button>
      </nb-card-footer>
  </nb-card>
</ng-template>

<!-- Notification Ref -->
<ng-template #notificationRef>
  <nb-card class="npopview">
    <nb-card-header>
      Notifications
      <span style="float: right; cursor: pointer;">
        <a (click)="notificationPopover.hide()" [nbTooltip]="constant.CLOSE" nbTooltipPlacement="top">
            <nb-icon icon="close-outline"></nb-icon>
        </a>
    </span>
    </nb-card-header>
    <nb-card-body *ngIf="notificationList?.length">
      <ng-container *ngFor="let notification of notificationList">
        <p>{{notification.title}}</p>
        <p>{{notification.body}}</p>
        <!-- <a *ngIf="!notification.read" [nbTooltip]="constant.MARK_AS_READ" nbTooltipPlacement="bottom" style="cursor: pointer;" (click)="markAsReadNotification(notification._id)">
          <nb-icon icon="checkmark-outline"></nb-icon>
        </a> -->

        <span style="margin-left: 5px; color: red; cursor: pointer;">
          <!-- <a [nbTooltip]="constant.DELETE" nbTooltipPlacement="bottom" (click)="deleteNotification(notification._id)">
            <nb-icon icon="trash-outline"></nb-icon>
          </a> -->
        </span>    
                        
        <label style="float: right; font-size: 13px;">{{ getNotificationTime(notification.created) }}</label>
        <hr>
      </ng-container>
    </nb-card-body>

    <nb-card-body *ngIf="!notificationList?.length">
      <img src="assets/images/undraw_category.png" alt="cupan" class="img-discount">
      <h4 style="text-align: center;">No notification found</h4>
    </nb-card-body>

    <nb-card-footer>
      <div class="text-center" >
        <button  nbButton status="info" outline (click)="moreNotification()" [nbTooltip]="constant.VIEW_ALL_NOTIFICATION" nbTooltipPlacement="bottom">
          {{ constant.VIEW_ALL_NOTIFICATION }}
        </button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<!-- Processing Ref -->
<ng-template #processingRef let-data let-ref="dialogRef">
  <nb-card  status="primary" [nbSpinner]="loading" nbSpinnerStatus="success" [nbSpinnerMessage]="spinnerMessage" style="width: 70vh; height: 60vh;">
      <nb-card-body style="text-align: center;">
          <img src="assets/images/undraw_inProgress.png" width="250px" height="250px">
      </nb-card-body>
  </nb-card>
</ng-template>

<!-- Captureing Ref -->
<ng-template #captureingRef let-data let-ref="dialogRef">
  <nb-card  status="primary" [nbSpinner]="loading" nbSpinnerStatus="success" [nbSpinnerMessage]="spinnerMessage" style="width: 70vh; height: 60vh;">
      <nb-card-body style="text-align: center;">
          <img src="assets/images/undraw_wallet.png" width="280px" height="230px">
      </nb-card-body>
  </nb-card>
</ng-template>

<!-- Quick Actions -->
<ng-template #quickAction>
  <nb-card style="margin-bottom: 0rem;">
    <nb-menu [items]="quickActionItems">
    </nb-menu>
  </nb-card>
</ng-template>