import { Injectable } from '@angular/core';
import { GlobalProviderService } from '../providers/global-provider.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StoresService {
  public storeUrl = environment.STORE_API_URL;
  constructor(
    private globalProvider: GlobalProviderService,
  ) { }

  public getOrderList() {
    const url = this.storeUrl + 'orders';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public getSingleOrderDetails(id) {
    const url = this.storeUrl + 'order/' + id;
    return new Promise((resolve, reject) => {
      this.globalProvider.httpGet(url)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public orderStatusUpdate(orderStatus, orderId) {
    const url = this.storeUrl + 'update_status/' + orderId;
    const body = {
      status: orderStatus,
    };
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, body)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public createOrder(orderObj) {
    const url = this.storeUrl + 'placeorder';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, orderObj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  public updateBusinessHours(storeId, hoursObj) {
    const url = this.storeUrl + storeId + '/update_business_hours';
    return new Promise((resolve, reject) => {
      this.globalProvider.httpPost(url, hoursObj)
        .then(data => {
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }
}
